.errorPage {
    @apply fixed h-screen w-full;
}
.errorPage .inner {
    @apply flex h-screen w-full items-center justify-center flex-col text-center;
}
.errorPage .heading {
    @apply mb-16 ipad:mb-9 mobile:mb-4 font-bold text-[120px] text-interface-500 ipad:text-[80px] mobile:text-[50px];
}
.errorPage .subHeading {
    @apply text-4xl ipad:text-[25px] mobile:text-lg text-interface-700;
}
.errorPage .description {
    @apply mx-auto mb-0 max-w-[700px] text-lg text-interface-900 mobile:text-base;
}
.errorPage .Logo {
    @apply flex w-full items-center justify-center relative -top-[70px] mobile:-top-8;
}

